import classNames from 'classnames';

import { DefaultBezelAdvantageItem } from './DefaultBezelAdvantageItem';

import { DefaultAdvantageItemsProps } from '../common/types';

export const DefaultBezelAdvantageItemsTestIds = {
  container: 'default-bezel-advantage-items-container'
};

export const DefaultBezelAdvantageItems: React.FC<DefaultAdvantageItemsProps> = ({
  items,
  container,
  className,
  style,
  children
}) => (
  <div
    data-testid={DefaultBezelAdvantageItemsTestIds.container}
    className={classNames('w-100', container?.className)}
    style={container?.style}
  >
    <div
      className={classNames(
        'main-content d-flex flex-column flex-md-row riforma-regular gap-4 pt-4 pb-4 pt-md-6 pb-md-5 px-4',
        className
      )}
      style={style}
    >
      {items.map((item, index) => {
        const isStart = index === 0;
        const isEnd = index === items.length - 1;
        const isBetween = !isStart && !isEnd;

        return (
          <DefaultBezelAdvantageItem
            key={`${item.title}-${index}`}
            className={classNames(isStart && 'me-md-2', isEnd && 'ms-md-3', isBetween && 'mx-md-3')}
            {...item}
          />
        );
      })}
      {children}
    </div>
  </div>
);
