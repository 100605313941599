import { FC } from 'react';
import classNames from 'classnames';

import { getFormattedCountdownDate, useCountdown } from 'hooks/useCountdown';

const endText = 'Auction ended';

type AuctionListingCountdown = {
  endDate: string;
};

export const AuctionListingCountdownMobile: FC<AuctionListingCountdown> = ({ endDate }) => {
  const countdown = useCountdown(endDate);
  const formattedCountdownDate = getFormattedCountdownDate(countdown, endDate);

  return (
    <div className='d-flex ms-4 text-light40'>
      {formattedCountdownDate !== endText && <div className='w-40'>ENDS</div>}
      <div
        className={classNames('riforma-medium ps-1', {
          'w-60': formattedCountdownDate !== endText,
          'text-turquoise': parseInt(countdown.days) >= 1 || countdown.hasEnded,
          'text-danger': parseInt(countdown.days) < 1 && !countdown.hasEnded
        })}
      >
        {formattedCountdownDate}
      </div>
    </div>
  );
};

export const AuctionListingCountdownDesktop: FC<AuctionListingCountdown> = ({ endDate }) => {
  const countdown = useCountdown(endDate);
  const formattedCountdownDate = getFormattedCountdownDate(countdown, endDate);

  return (
    <div className='me-1 text-light40'>
      {formattedCountdownDate !== endText && 'ENDS IN'}{' '}
      <span
        className={classNames('ps-1', {
          'text-turquoise': parseInt(countdown.days) >= 1 || countdown.hasEnded,
          'text-danger': parseInt(countdown.days) < 1 && !countdown.hasEnded
        })}
      >
        {formattedCountdownDate}
      </span>
    </div>
  );
};
