import classNames from 'classnames';

import { useViewport } from 'context/viewport';
import { StyledComponent } from 'types';

import { AdvantageItem } from '../common/types';

interface DefaultBezelAdvantageItemProps extends AdvantageItem, StyledComponent {}

export const DefaultBezelAdvantageItem: React.FC<DefaultBezelAdvantageItemProps> = ({
  title,
  description,
  icon,
  className,
  style,
  textContainer,
  iconToContentSpacer,
  titleToDescriptionSpacer
}) => {
  const IconComponent = icon.IconComponent;
  const { isMobile } = useViewport();

  return (
    <div
      className={classNames('ps-4 ps-md-0 d-flex flex-column justify-content-start', className)}
      style={{ borderLeft: isMobile ? '1px solid rgba(26, 58, 50, 0.2)' : undefined, ...style }}
    >
      <div
        className={classNames(icon.className, 'd-flex align-items-center justify-content-center rounded-3')}
        style={{ backgroundColor: 'rgba(52, 48, 45, 0.1)', width: 'fit-content', ...icon.style }}
      >
        {IconComponent && <IconComponent className='m-2 m-md-3 ' size={isMobile ? 16 : 23} {...icon.iconProps} />}
        {icon.content && <>{icon.content}</>}
      </div>
      <div className={classNames('pb-2 pb-md-4', iconToContentSpacer?.className)} style={iconToContentSpacer?.style} />
      <div className={textContainer?.className} style={textContainer?.style}>
        <div className={classNames('fs-18px', title.className)} style={{ color: '#34302D', ...title.style }}>
          {title.content}
        </div>
        <div
          className={classNames('pb-2 pb-md-3', titleToDescriptionSpacer?.className)}
          style={titleToDescriptionSpacer?.style}
        />
        <div
          className={classNames('fs-14px text-primary opacity-60 line-height-24px', description.className)}
          style={description.style}
        >
          {description.content}
        </div>
      </div>
    </div>
  );
};
