import { FaArrowRight } from '@react-icons/all-files/fa6/FaArrowRight';

import { EquinoxModal } from 'components/equinox/EquinoxModal';
import { useModal } from 'context/modal/ModalContext';

import { DefaultBannerBackground } from './common/DefaultBannerBackground';
import { DefaultBannerButton } from './common/DefaultBannerButton';
import { DefaultBannerContentContainer } from './common/DefaultBannerContentContainer';
import { DefaultBannerSubtitle } from './common/DefaultBannerSubtitle';
import { DefaultBannerTitle } from './common/DefaultBannerTitle';

export const EquinoxBanner = () => {
  const { openModal, closeModal } = useModal();

  const onClickLearnMore = () => openModal(<EquinoxModal closeModal={closeModal} />);

  return (
    <DefaultBannerBackground
      image={{
        src: 'equinox-background-desktop.png',
        alt: `Equinox Banner Background Image`
      }}
    >
      <DefaultBannerContentContainer className='text-light' style={{ top: '-18%' }}>
        <DefaultBannerSubtitle content='Feature' />
        <DefaultBannerTitle
          content={
            <>
              Welcome to
              <br />
              Bezel | Equinox
            </>
          }
          className='w-80 w-sm-55'
        />
        <DefaultBannerButton
          content='Learn more'
          endIcon={FaArrowRight}
          onClick={onClickLearnMore}
          textClassname='text-light'
        />
      </DefaultBannerContentContainer>
    </DefaultBannerBackground>
  );
};
