import 'swiper/css';

import { FC, Fragment, useEffect, useState } from 'react';
import { FaAngleLeft } from '@react-icons/all-files/fa6/FaAngleLeft';
import { FaAngleRight } from '@react-icons/all-files/fa6/FaAngleRight';
import classNames from 'classnames';
import chunk from 'lodash/chunk';
import { Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType, SwiperModule, VirtualOptions } from 'swiper/types';

import {
  ArrowClassname,
  defaultArrowClassname,
  defaultPreRenderedVirtualSlidesAmount
} from 'components/common/Carousel';
import { BrandCard, BrandCardLoading } from 'components/home/BrandCard';
import { useViewport } from 'context/viewport';
import carouselStyles from 'styles/Carousel.module.scss';
import styles from 'styles/ModelCarousel.module.scss';
import { Brand, StyledComponent } from 'types';

const SLIDES_PER_VIEW = 4;
const SLIDES_PER_VIEW_MOBILE = 1.8;

interface BrandCarouselProps extends StyledComponent {
  title: string;
  items: Brand[];
  loading?: boolean;
  showArrowGradient?: boolean;
  arrowClassname?: ArrowClassname;
}

const placeHolders = Array.from(new Array(12)).map((_, i) => ({ id: i }));
const swiperModules: SwiperModule[] = [Virtual];

export const BrandCarousel: FC<BrandCarouselProps> = ({
  title,
  className,
  items,
  loading,
  showArrowGradient,
  arrowClassname
}) => {
  const { isTabletOrMobile, isMobileSmall } = useViewport();

  const [swiper, setSwiper] = useState<SwiperType>();
  const [idx, setIdx] = useState(0);

  const slidesPerView = isMobileSmall ? SLIDES_PER_VIEW_MOBILE : SLIDES_PER_VIEW;
  const brands = loading ? placeHolders : items;
  const numberOfChunks = isMobileSmall ? 1 : 2;
  const brandChunks: (Brand | { id: number })[][] = chunk(brands, numberOfChunks);

  const swiperVirtualOptions: VirtualOptions = {
    cache: true,
    ...(isMobileSmall && {
      addSlidesAfter: defaultPreRenderedVirtualSlidesAmount,
      addSlidesBefore: defaultPreRenderedVirtualSlidesAmount
    })
  };

  useEffect(() => {
    if (swiper) {
      swiper.allowTouchMove = isTabletOrMobile;
    }
  }, [swiper, isTabletOrMobile]);

  useEffect(() => {
    if (swiper && !swiper.destroyed && swiper.activeIndex !== idx) {
      swiper.slideTo(idx);
    }
  }, [idx, swiper]);

  const incrementIdx = () => {
    if (idx < brands.length / 2) {
      setIdx(idx + 1);
    }
  };

  const decrementIdx = () => {
    if (idx > 0) {
      setIdx(idx - 1);
    }
  };

  return (
    <div className={className}>
      <div className='fs-18px riforma-medium text-primary px-4'>{title}</div>
      <div className='d-flex align-items-center w-100 position-relative'>
        {brands.length >= SLIDES_PER_VIEW * 2 && !isTabletOrMobile && (
          <div
            className={classNames(
              'd-flex h-100 position-absolute start-0',
              idx > 0 ? 'opacity-100' : 'opacity-0',
              showArrowGradient && 'w-5'
            )}
            style={{ zIndex: '2' }}
            role='button'
            aria-label='Previous brands'
            onClick={decrementIdx}
          >
            <div
              className={classNames(
                'h-100 d-flex',
                loading || showArrowGradient ? 'bg-light' : 'bg-transparent',
                defaultArrowClassname.left,
                arrowClassname?.left
              )}
            >
              <div className='px-2 align-self-center'>
                <FaAngleLeft />
              </div>
            </div>
            {showArrowGradient && (
              <div
                className={classNames(
                  'h-100 w-100 position-relative',
                  styles.gradientRight,
                  carouselStyles['gradient-right']
                )}
              />
            )}
          </div>
        )}
        <Swiper
          className={classNames('py-3 w-100', styles['carousel-swiper'])}
          onSwiper={setSwiper}
          slidesPerView={slidesPerView}
          spaceBetween={isMobileSmall ? 10 : 15}
          onSlideChange={swiperItem => setIdx(swiperItem.activeIndex)}
          watchSlidesProgress={!isMobileSmall}
          modules={swiperModules}
          virtual={swiperVirtualOptions}
        >
          {brandChunks.map((chunkItem, index) => (
            <SwiperSlide key={`brand-chunk-${index}`} virtualIndex={index}>
              <div className='d-flex flex-column justify-content-between gap-2'>
                {chunkItem.map((brand, chunkIdx) => (
                  <Fragment key={`brand-${chunkIdx}}`}>
                    {loading ? (
                      <BrandCardLoading isMobile={isMobileSmall} />
                    ) : (
                      <BrandCard
                        key={`brand-${chunkIdx}-${brand?.id}}`}
                        brand={brand as Brand}
                        isMobile={isMobileSmall}
                      />
                    )}
                  </Fragment>
                ))}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {brands.length >= SLIDES_PER_VIEW * 2 && !isTabletOrMobile && (
          <div
            role='button'
            onClick={incrementIdx}
            className={classNames(
              'd-flex h-100 position-absolute end-0',
              idx < brands.length / 2 - SLIDES_PER_VIEW ? 'opacity-100' : 'opacity-0',
              showArrowGradient && 'w-5'
            )}
            style={{ zIndex: '2' }}
          >
            {showArrowGradient && (
              <div
                className={classNames(
                  'h-100 w-100 position-relative',
                  styles.gradientLeft,
                  carouselStyles['gradient-left']
                )}
              />
            )}
            <div
              className={classNames(
                'h-100 d-flex',
                loading || showArrowGradient ? 'bg-light' : 'bg-transparent',
                defaultArrowClassname.right,
                arrowClassname?.right
              )}
            >
              <div className='px-2 align-self-center'>
                <FaAngleRight />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
