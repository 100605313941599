import classNames from 'classnames';

import { DefaultBannerText } from './types';

export const DefaultBannerTitleTestIds = {
  container: 'default-banner-title-container'
};

export const DefaultBannerTitle: React.FC<DefaultBannerText> = ({ className, style, content, children }) => (
  <h1
    data-testid={DefaultBannerTitleTestIds.container}
    className={classNames('fs-20px line-height-28px line-height-md-32px fs-md-24px', className)}
    style={style}
  >
    {content}
    {children}
  </h1>
);
