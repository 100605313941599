import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { type TreatmentsWithConfig } from '@splitsoftware/splitio/types/splitio';
import compact from 'lodash/compact';

import { Loading } from 'components/common/Loading';
import { BannerCarousel } from 'components/home/BannerCarousel';
import { HomeHead } from 'components/home/HomeHead';
import {
  HomeFeedItem,
  homeFeedSectionMapsComponent,
  MapCarouselItemsComponent
} from 'components/home/MapCarouselItemsComponent';
import { AuthAdvantageItems } from 'components/home/v2/advantage/auth/AuthAdvantageItems';
import { BezelAdvantageItems } from 'components/home/v2/advantage/bezel/BezelAdvantageItems';
import { ConciergeCard } from 'components/home/v2/cards/ConciergeCard';
import { InvestorsCard } from 'components/home/v2/cards/InvestorsCard';
import { ReferralCard } from 'components/home/v2/cards/ReferralCard';
import { WhyBezelCard } from 'components/home/v2/cards/WhyBezelCard';
import { AuthenticateBanner } from 'components/listing_detail/v2/AuthenticateBanner';
import { SellersHead } from 'components/sellers/SellersHead';
import { useBranding } from 'context/branding';
import { getSSRTreatments } from 'context/featureToggle';
import { useViewport } from 'context/viewport';
import { getUser } from 'lib/api/users';
import { clientApiGet } from 'lib/api_helper';
import { apiUrls } from 'lib/api_urls';
import { cacheKeys } from 'lib/cache_keys';
import {
  Feature,
  HomeFeed,
  HomeFeedSection,
  HomeFeedSectionItemType,
  ListingStat,
  ListObject,
  SECTIONS_TYPE,
  SellerSiteMetadata,
  User
} from 'types';
import { getAuth0Client } from 'utils/auth0';
import * as Cache from 'utils/redis';

import SellerPage from './sellers/[seller_slug]';

import { callWithCache } from '../lib/cache';

interface HomePageProps {
  homeFeedSections: HomeFeedSection<HomeFeedSectionItemType>[];
  ssrTreatments: TreatmentsWithConfig;
  user: User;
  sellerSiteMetadata: SellerSiteMetadata | null;
  bannerItems: Feature[];
  listingStatsCount: number;
  auctionsStats: ListingStat;
  firstTwoListsItems: HomeFeedItem[][];
}

const spacerCarouselToCarouselClassname = 'my-4';

const Homepage: React.FC<HomePageProps> = ({
  homeFeedSections,
  sellerSiteMetadata,
  user,
  bannerItems,
  listingStatsCount,
  auctionsStats,
  firstTwoListsItems
}) => {
  const { isLoaded } = useViewport();
  const isLoading = !isLoaded;

  const { useSellerBranding } = useBranding();

  const isServerFirstTwoListsAvailable = firstTwoListsItems.length === 2;

  if (sellerSiteMetadata || useSellerBranding) {
    return (
      <>
        <SellersHead sellerSiteMetadata={sellerSiteMetadata} />
        <SellerPage />
      </>
    );
  }

  return (
    <>
      <HomeHead />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <BannerCarousel
            bannerItems={bannerItems}
            listingStatsCount={listingStatsCount}
            auctionsStats={auctionsStats}
          />
          <div className='py-2 py-sm-4' />
          {homeFeedSections.slice(1, 3).map((homeFeedSection, index) => (
            <MapCarouselItemsComponent
              key={homeFeedSection.id}
              type={homeFeedSection.type}
              spacerClassname={spacerCarouselToCarouselClassname}
              title={homeFeedSection.title}
              objectId={homeFeedSection.object.id}
              serverItems={isServerFirstTwoListsAvailable ? firstTwoListsItems[index] : undefined}
            />
          ))}
          <div className='py-2 py-sm-4' />
          {homeFeedSections.slice(3, 4).map(homeFeedSection => (
            <MapCarouselItemsComponent
              key={homeFeedSection.id}
              type={homeFeedSection.type}
              spacerClassname={spacerCarouselToCarouselClassname}
              title={homeFeedSection.title}
              objectId={homeFeedSection.object.id}
            />
          ))}
          <div className='py-2 py-sm-4' />
          <WhyBezelCard />
          <BezelAdvantageItems />
          <div className='py-2 py-sm-4' />
          <AuthenticateBanner />
          <AuthAdvantageItems />
          <div className='py-2 py-sm-4' />
          {homeFeedSections.slice(4, 6).map(homeFeedSection => (
            <MapCarouselItemsComponent
              key={homeFeedSection.id}
              type={homeFeedSection.type}
              spacerClassname={spacerCarouselToCarouselClassname}
              title={homeFeedSection.title}
              objectId={homeFeedSection.object.id}
            />
          ))}
          <div className='py-2 py-sm-4' />
          <ConciergeCard />
          <div className='py-2 py-sm-4' />
          {homeFeedSections.slice(6).map(homeFeedSection => (
            <MapCarouselItemsComponent
              key={homeFeedSection.id}
              type={homeFeedSection.type}
              spacerClassname={spacerCarouselToCarouselClassname}
              title={homeFeedSection.title}
              objectId={homeFeedSection.object.id}
            />
          ))}
          <div className='py-2 py-sm-4' />
          {user && <ReferralCard />}
          <InvestorsCard />
        </>
      )}
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context: GetServerSidePropsContext) => {
  context.res.setHeader('Cache-Control', 'public, s-maxage=60, stale-while-revalidate=59');

  const auth0Client = await getAuth0Client();

  try {
    const { accessToken } = await auth0Client
      .getAccessToken(context.req, context.res)
      .catch(() => ({ accessToken: null }));
    const session = await auth0Client.getSession(context.req, context.res);
    const host = context.req.headers.host;

    let sellerSiteMetadataPromise: Promise<SellerSiteMetadata[] | null> | null = null;

    if (host && process.env.NEXT_PUBLIC_SITE_BASE_URL && !process.env.NEXT_PUBLIC_SITE_BASE_URL.includes(host)) {
      const cacheKey = cacheKeys.sellerSiteDomain(host);

      const cachedResponse = await Cache.get(cacheKey);

      if (cachedResponse) {
        sellerSiteMetadataPromise = Promise.resolve(JSON.parse(cachedResponse));
      } else {
        sellerSiteMetadataPromise = clientApiGet<SellerSiteMetadata[] | null>(
          apiUrls.sellerMetaData({
            'domains[]': [host],
            'expand[]': ['lightLogoImage', 'darkLogoImage', 'heroImage', 'businessAddress', 'faviconImage']
          })
        ).then(data => Cache.set(cacheKey, JSON.stringify(data), 86400).then(() => data));
      }
    }

    const homeFeedsPromise = clientApiGet<HomeFeed<HomeFeedSectionItemType>>(apiUrls.homeFeedsLive(), { accessToken });
    const ssrTreatmentsPromise = getSSRTreatments();
    let userPromise;

    if (session && session.user && accessToken) {
      userPromise = getUser(session.user.sub, accessToken);
    } else {
      userPromise = Promise.resolve(null);
    }

    const listingStatsCountPromise = callWithCache(cacheKeys.listingsCount(), 3600, async () => {
      const listingStats = await clientApiGet<ListingStat>(
        `${process.env.NEXT_PUBLIC_API_BASE_URL}/marketplace/listings/stats?active=true`,
        { accessToken }
      );

      return listingStats.count;
    });

    const auctionsStatsPromise = clientApiGet<ListingStat>(
      `${process.env.NEXT_PUBLIC_API_BASE_URL}/marketplace/listings/stats?active=true&activePricingModel=AUCTION`,
      { accessToken }
    );

    const [homeFeed, ssrTreatments, user, sellerSiteMetadataList, listingStatsCount, auctionsStats] = await Promise.all(
      [
        homeFeedsPromise,
        ssrTreatmentsPromise,
        userPromise,
        sellerSiteMetadataPromise,
        listingStatsCountPromise,
        auctionsStatsPromise
      ]
    );

    const isSellerSiteMetadataAvailable = sellerSiteMetadataList && sellerSiteMetadataList.length > 0;

    const sellerSiteMetadata = isSellerSiteMetadataAvailable ? sellerSiteMetadataList[0] : null;
    const homeFeedSections = compact(homeFeed.sections);

    const featureFeedSection = homeFeedSections.find(
      section => section.type === SECTIONS_TYPE.FeatureCluster
    ) as HomeFeedSection<Feature>;

    let bannerItems: Feature[] = [];

    let bannerItemsPromise: Promise<{ items: Feature[] }> | undefined = undefined;

    if (featureFeedSection) {
      const featureId = featureFeedSection?.object.id;

      const serverUrl =
        process.env.NEXT_PUBLIC_SITE_BASE_URL + apiUrls.getList(featureId, { 'expand[]': 'items.object' });

      bannerItemsPromise = clientApiGet<{ items: Feature[] }>(serverUrl);
    }

    const firstTwoSectionsPromise = homeFeedSections.slice(1, 3).map(section => {
      const { query } = homeFeedSectionMapsComponent[section.type] || { Component: null };
      const objectId = section.object.id;

      const listRequestUrl =
        process.env.NEXT_PUBLIC_SITE_BASE_URL + apiUrls.getList(objectId, query ? { 'expand[]': query } : undefined);

      return clientApiGet(listRequestUrl) as Promise<ListObject<HomeFeedItem>>;
    });

    const [firstSectionPromise, secondSectionPromise] = firstTwoSectionsPromise;

    const [firstListData, secondListData, bannerItemsData] = await Promise.all([
      firstSectionPromise,
      secondSectionPromise,
      bannerItemsPromise
    ]);

    if (bannerItemsData) {
      bannerItems = bannerItemsData.items?.filter(feature => !!feature.object);
    }

    const firstTwoListsItems = [firstListData, secondListData]
      .filter(listData => listData !== undefined)
      .map(listData => listData.items);

    return {
      props: {
        homeFeedSections,
        ssrTreatments,
        user,
        sellerSiteMetadata,
        bannerItems,
        listingStatsCount,
        auctionsStats,
        firstTwoListsItems
      }
    };
  } catch (e) {
    return {
      props: {
        homeFeedSections: [],
        ssrTreatments: {},
        user: null,
        sellerSiteMetadata: null,
        bannerItems: [],
        listingStatsCount: null,
        auctionsStats: null,
        firstTwoListsItems: []
      }
    };
  }
};

export default Homepage;
