import { FC } from 'react';
import { FaArrowRight } from '@react-icons/all-files/fa6/FaArrowRight';
import { useRouter } from 'next/navigation';

import { useBezelUser } from 'context/bezelUser';
import { useViewport } from 'context/viewport';
import { formatPrice } from 'utils/text-helpers';

import { DefaultCardButton } from './common/DefaultCardButton';
import { DefaultCardContainer } from './common/DefaultCardContainer';
import { DefaultCardContent } from './common/DefaultCardContent';
import { DefaultCardContentContainer } from './common/DefaultCardContentContainer';
import { DefaultCardGraphic } from './common/DefaultCardGraphic';
import { DefaultCardHeadingSubtitle } from './common/DefaultCardHeadingSubtitle';
import { DefaultCardSubtitle } from './common/DefaultCardSubtitle';

export const ReferralCard: FC = () => {
  const { user } = useBezelUser();
  const { push } = useRouter();
  const buyerProfile = user?.buyerProfile;
  const { isMobile } = useViewport();
  const onReferButtonClick = () => push('/refer');

  if (!buyerProfile) {
    return null;
  }

  return (
    <>
      {isMobile && <DefaultCardHeadingSubtitle content='Refer a friend' />}
      <DefaultCardContainer
        height={450}
        className='mb-5'
        style={{
          background: isMobile ? '#EEE9E3' : 'linear-gradient(90deg, #EEE9E3 0%, #FFFCF9 100%), #1F1E1D',
          border: '2px solid #f1eeec'
        }}
      >
        <DefaultCardContentContainer>
          {!isMobile && <DefaultCardSubtitle content='Refer a friend' iconProps={{ size: isMobile ? 14 : 16 }} />}
          <DefaultCardContent
            title={
              <>
                <div className='fs-20px fs-md-28px line-height-32px line-height-md-40px mb-3'>{`Split ${formatPrice(
                  buyerProfile?.referralDiscountCents + buyerProfile?.referralRewardCents
                )} with your friend`}</div>
                <div className='opacity-60 fs-14px fs-md-16px line-height-20px line-height-md-24px white-space-break'>
                  {`Your friend will get ${formatPrice(
                    buyerProfile?.referralDiscountCents
                  )} off their first purchase and you’ll get ${formatPrice(
                    buyerProfile?.referralRewardCents
                  )} in credits once they buy`}
                </div>
              </>
            }
          />
          <DefaultCardButton
            content='Refer friends'
            endIcon={FaArrowRight}
            style={{
              backgroundColor: 'rgba(31, 25, 17, 0.06)',
              color: '#1F1911'
            }}
            onClick={onReferButtonClick}
          />
        </DefaultCardContentContainer>

        <DefaultCardGraphic
          image={{
            src: 'referrals.png',
            width: 3640,
            quality: 100,
            className: 'py-md-4 pe-md-4',
            style: {
              backgroundColor: isMobile ? '#FAF7F3' : undefined,
              backgroundPosition: isMobile ? 'center' : 'right 50px bottom 0px',
              backgroundSize: isMobile ? '90%' : 'auto 85%',
              objectFit: 'contain',
              objectPosition: isMobile ? '' : 'right 0px bottom -18px'
            }
          }}
        />
      </DefaultCardContainer>
    </>
  );
};
