import { MdOutlineForum } from '@react-icons/all-files/md/MdOutlineForum';
import { MdOutlineScience } from '@react-icons/all-files/md/MdOutlineScience';
import { MdOutlineWatch } from '@react-icons/all-files/md/MdOutlineWatch';

import { DefaultBezelAdvantageItems } from './DefaultBezelAdvantageItems';

import { AdvantageItem } from '../common/types';

const bezelAdvantageItems: AdvantageItem[] = [
  {
    title: {
      content: 'The best inventory'
    },
    description: {
      content:
        'Shop thousands of the most collectable watches on the planet from all of the top luxury brands, all in one place'
    },
    icon: {
      IconComponent: MdOutlineWatch
    }
  },
  {
    title: {
      content: 'In-house authentication'
    },
    description: {
      content: 'Everything is sent to our in-house experts for multi-point Bezel certification before it gets to you'
    },
    icon: {
      IconComponent: MdOutlineScience
    }
  },
  {
    title: {
      content: 'Private Bezel Concierge'
    },
    description: {
      content:
        'Whether you want to source a hard to find watch or need recommendations, your private client advisor is here to help'
    },
    icon: {
      IconComponent: MdOutlineForum
    }
  }
];

export const BezelAdvantageItems = () => (
  <DefaultBezelAdvantageItems className='my-4 gap-md-5' items={bezelAdvantageItems} />
);
