import { useViewport } from 'context/viewport';

import { DefaultCardContainer } from './common/DefaultCardContainer';
import { DefaultCardContent } from './common/DefaultCardContent';
import { DefaultCardContentContainer } from './common/DefaultCardContentContainer';
import { DefaultCardGraphic } from './common/DefaultCardGraphic';
import { DefaultCardHeadingSubtitle } from './common/DefaultCardHeadingSubtitle';
import { DefaultCardSubtitle } from './common/DefaultCardSubtitle';
import { homeCardHeight } from './consts';

export const WhyBezelCard = () => {
  const { isMobile } = useViewport();

  return (
    <>
      {isMobile && <DefaultCardHeadingSubtitle content='Why Bezel?' />}
      <DefaultCardContainer
        className='text-primary'
        height={homeCardHeight}
        color='#FFFCF9'
        style={{ border: '2px solid #EDE8E4', transform: 'translate3d(0, 0, 0)' }}
      >
        <DefaultCardContentContainer>
          {!isMobile && <DefaultCardSubtitle content='Why Bezel?' />}
          <DefaultCardContent
            className='me-0 me-md-7'
            title={
              <>
                We&apos;re watch buyers like you that wanted something{' '}
                <span className='bradford-regular-italic'>better</span>
              </>
            }
            description='We started Bezel because we believe that everyone, first-time buyers to seasoned collectors, should be able to find what they want with complete confidence.'
          />
        </DefaultCardContentContainer>
        <DefaultCardGraphic
          image={{
            src: 'why-bezel.png',
            width: 3640,
            quality: 100
          }}
        />
      </DefaultCardContainer>
    </>
  );
};
