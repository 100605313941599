import { FaArrowRight } from '@react-icons/all-files/fa6/FaArrowRight';
import { MdOutlineForum } from '@react-icons/all-files/md/MdOutlineForum';

import { useViewport } from 'context/viewport';
import { intercomShowNewMessage } from 'lib/intercom';

import { DefaultCardButton } from './common/DefaultCardButton';
import { DefaultCardContainer } from './common/DefaultCardContainer';
import { DefaultCardContent } from './common/DefaultCardContent';
import { DefaultCardContentContainer } from './common/DefaultCardContentContainer';
import { DefaultCardGraphic } from './common/DefaultCardGraphic';
import { DefaultCardHeadingSubtitle } from './common/DefaultCardHeadingSubtitle';
import { DefaultCardSubtitle } from './common/DefaultCardSubtitle';
import { homeCardHeight } from './consts';

export const ConciergeCard = () => {
  const { isMobile, isMobileSmall } = useViewport();

  return (
    <>
      {isMobile && <DefaultCardHeadingSubtitle content='Bezel Concierge' />}
      <DefaultCardContainer
        className='text-light'
        height={homeCardHeight}
        style={{
          background: 'linear-gradient(90deg, rgba(238, 233, 227, 0.00) 46.6%, rgba(238, 233, 227, 0.10) 100%), #1F1E1D'
        }}
      >
        <DefaultCardContentContainer>
          {!isMobile && (
            <DefaultCardSubtitle
              content='Bezel Concierge'
              startIcon={MdOutlineForum}
              iconProps={{ size: isMobile ? 14 : 16 }}
            />
          )}
          <DefaultCardContent
            title={
              <>
                Your <span className='bradford-regular-italic'>private</span> watch concierge is waiting to source you
                anything you&apos;re looking for
              </>
            }
          />
          <DefaultCardButton
            content='Chat now'
            endIcon={FaArrowRight}
            style={{ backgroundColor: 'rgba(255, 252, 249, 0.06)' }}
            onClick={intercomShowNewMessage}
          />
        </DefaultCardContentContainer>
        <DefaultCardGraphic
          image={{
            src: isMobileSmall
              ? 'https://storage.googleapis.com/public-us-standard-bezel_sandbox/image/conceirge-card-foreground-mobile-bg-transparent.png'
              : 'conceirge-card-foreground-desktop.png',
            width: 3640,
            quality: 100,
            style: {
              backgroundColor: isMobile ? '#363533' : undefined
            }
          }}
        />
      </DefaultCardContainer>
    </>
  );
};
