import { useEffect, useState } from 'react';

import { ONE_DAY, ONE_HOUR, ONE_MINUTE } from 'utils/date';

const calculateTimeRemaining = (timeRemaining: number) => {
  const days = Math.floor(timeRemaining / ONE_DAY);
  const hours = Math.floor((timeRemaining % ONE_DAY) / ONE_HOUR);
  const minutes = Math.floor((timeRemaining % ONE_HOUR) / ONE_MINUTE);
  const seconds = Math.floor((timeRemaining % ONE_MINUTE) / 1000);

  return {
    days: String(days),
    hours: hours < 10 ? '0' + hours : String(hours),
    minutes: minutes < 10 ? '0' + minutes : String(minutes),
    seconds: seconds < 10 ? '0' + seconds : String(seconds),
    hasEnded: timeRemaining <= 0
  };
};

export const getFormattedCountdownDate = (countdown: TimeRemaining, auctionEnd: string) => {
  if (parseInt(countdown.days) >= 1) {
    const nowDate = new Date();

    nowDate.setHours(0, 0, 0, 0);
    const endDate = new Date(auctionEnd);

    endDate.setHours(0, 0, 0, 0);

    const timeDiff = new Date(endDate).getTime() - new Date(nowDate).getTime();
    const timeRemaining = calculateTimeRemaining(timeDiff);

    if (parseInt(timeRemaining.days) === 1) {
      return '1 day';
    }

    return `${timeRemaining.days} days`;
  } else if (countdown.hasEnded) {
    return 'Auction ended';
  }

  return `${countdown.hours}:${countdown.minutes}:${countdown.seconds}`;
};

type TimeRemaining = {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
  hasEnded: boolean;
};

const emptyDate = { days: '0', hours: '00', minutes: '00', seconds: '00', hasEnded: true };

export const useCountdown = (endDate?: string | number) => {
  const [timeRemaining, setTimeRemaining] = useState<TimeRemaining>(emptyDate);

  useEffect(() => {
    setTimeRemaining(endDate ? calculateTimeRemaining(new Date(endDate).getTime() - new Date().getTime()) : emptyDate);
  }, [endDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (endDate === undefined) {
        return emptyDate;
      }
      const timeDiff = new Date(endDate).getTime() - new Date().getTime();

      if (timeDiff <= 0) {
        clearInterval(interval);

        return emptyDate;
      }
      const newTimeRemaining = calculateTimeRemaining(timeDiff);

      setTimeRemaining(newTimeRemaining);
    }, 1000);

    return () => clearInterval(interval);
  }, [endDate]);

  return timeRemaining;
};
