import { FC, memo } from 'react';
import classNames from 'classnames';
import { useSwiperSlide } from 'swiper/react';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { Link } from 'components/common/Link';
import { ContentLoader } from 'components/design-system/ContentLoader';
import { pageRoutes } from 'lib/routes';
import brandCardStyles from 'styles/BrandCard.module.scss';
import styles from 'styles/Carousel.module.scss';
import { Brand } from 'types/brand';

type BrandProps = {
  isMobile: boolean;
};

type BrandCardProps = {
  brand: Brand;
} & BrandProps;

const getHeight = (isMobile: boolean) => (isMobile ? '88px' : '160px');

export const BrandCardLoading: FC<BrandProps> = memo(({ isMobile }) => (
  <div className={brandCardStyles['brand-card-loading']}>
    <ContentLoader borderRadius={20} height={getHeight(isMobile)} />
  </div>
));

export const BrandCard: FC<BrandCardProps> = ({ brand, isMobile }) => {
  const swiperSlide = useSwiperSlide();

  const showCard = swiperSlide == null || swiperSlide.isVisible || isMobile;

  if (!brand.heroModelImage?.optimizedUrl) {
    return (
      <Link href={pageRoutes.brand(brand.brandKey)}>
        <div
          role='button'
          className={classNames(
            'd-flex mb-md-1 align-items-center justify-content-center border rounded-2 shadow-on-hover',
            styles.card,
            showCard ? 'opacity-1' : 'opacity-0'
          )}
          style={{ height: getHeight(isMobile) }}
        >
          <div className='fs-14px fs-md-16px line-height-24px text-center text-primary riforma-regular'>
            {brand.displayName}
          </div>
        </div>
      </Link>
    );
  }

  return (
    <Link href={pageRoutes.brand(brand.brandKey)}>
      <div
        role='button'
        className={classNames(
          'd-flex mb-md-1 py-2 border rounded-2 shadow-on-hover',
          styles.card,
          {
            'flex-column justify-content-around': !isMobile
          },
          showCard ? 'opacity-1' : 'opacity-0'
        )}
        style={{ height: getHeight(isMobile) }}
      >
        <div
          className={classNames('position-relative d-flex', {
            'w-50': isMobile,
            'flex-1': !isMobile
          })}
        >
          {brand?.heroModelImage?.optimizedUrl && (
            <CloudinaryImage
              fill
              alt='Brand logo'
              sizes='(max-width: 768px) 50vw, 33vw'
              style={{ objectFit: 'contain' }}
              src={brand.heroModelImage.optimizedUrl}
            />
          )}
        </div>
        <div
          className={classNames('text-center fs-14px fs-md-16px line-height-24px text-primary riforma-regular', {
            'align-self-center me-2': isMobile
          })}
        >
          {brand.displayName}
        </div>
      </div>
    </Link>
  );
};
