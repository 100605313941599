export const formatAuctionDate = (date: string) => {
  const inputDate = new Date(date);

  if (!date) {
    return '';
  }

  const formattedDate = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' })
    .format(inputDate)
    .toUpperCase();
  const formattedTime = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short'
  }).format(inputDate);

  return `${formattedDate}, ${formattedTime}`;
};

export const formatAuctionDateNoTime = (date: string) => {
  if (!date) {
    return '';
  }
  const inputDate = new Date(date);

  const formattedDate = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: 'numeric', year: '2-digit' })
    .format(inputDate)
    .toUpperCase();

  return formattedDate;
};
