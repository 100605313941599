const getRoundedCount = (count: number, factor: number) => (Math.floor(count / factor) * factor).toLocaleString();

export const getListingCountDescription = (count: number) => {
  const countClassname = 'riforma-medium';

  if (!count || count < 100) {
    return '';
  }

  const factor = count >= 100 && count <= 999 ? 50 : 500;

  return (
    <>
      over <span className={countClassname}>{getRoundedCount(count, factor)}</span>
    </>
  );
};
