import { clientApiGet } from 'lib/api_helper';
import { getTitleFromListing } from 'lib/getTitleFromListing';
import { buildListingUrl } from 'lib/url_helpers';
import { Listing, ListingImageType, ProxyBid, ProxyBidStatus } from 'types';
import { formatPrice } from 'utils/text-helpers';

export const getAuctionDataFromListing = (listing: Listing) => {
  const name = getTitleFromListing(listing);

  const bidValue = listing.auctionInfo?.highestBid?.priceCents || listing.auctionInfo?.nextBidCents || null;
  const bid = bidValue === null ? null : formatPrice(bidValue);
  const imageUrl =
    listing?.images?.find(i => i.type === ListingImageType.FRONT)?.image.optimizedUrl || 'model_placeholder.png';
  const href = buildListingUrl(
    listing.model?.brand?.displayName,
    listing.model?.displayName,
    listing.model?.referenceNumber,
    listing.id
  );
  const year = listing.manufactureYear;

  return { href, imageUrl, bid, name, year };
};

type SuccessResult = {
  result: 'success';
  proxyBid: ProxyBid;
};

type OutbidResult = {
  result: 'outbid';
  proxyBid: ProxyBid;
};

type ErrorResult = {
  result: 'error';
  proxyBid: null;
};

type FetchResult = SuccessResult | OutbidResult | ErrorResult;

export const fetchAndValidateProxyBids = async (buyerProfileId: number, listingId: number): Promise<FetchResult> => {
  const fetchProxyBids = async () => {
    const proxyBids = await clientApiGet<ProxyBid[] | undefined>(
      `/api/auctions/proxy-bid/${buyerProfileId}/${listingId}`
    );

    const proxyBid = proxyBids && proxyBids[0];

    if (
      proxyBid &&
      (proxyBid.status === ProxyBidStatus.ACTIVE || proxyBid.status === ProxyBidStatus.EXHAUSTED) &&
      proxyBid.listing.auctionInfo?.highestBid?.buyerProfile?.id === buyerProfileId
    ) {
      resolveFn({ result: 'success', proxyBid });
    } else if (
      proxyBid &&
      proxyBid.status === ProxyBidStatus.EXHAUSTED &&
      proxyBid.listing.auctionInfo?.highestBid?.buyerProfile?.id !== buyerProfileId
    ) {
      resolveFn({ result: 'outbid', proxyBid });
    } else if (proxyBid && proxyBid.status === ProxyBidStatus.CANCELLED) {
      resolveFn({ result: 'error', proxyBid: null });
    }
  };

  let resolveFn: (value: FetchResult | PromiseLike<FetchResult>) => void;
  const fetchPromise = new Promise<FetchResult>(resolve => {
    resolveFn = resolve;
  });

  const intervalId = setInterval(async () => {
    await fetchProxyBids();
  }, 500);

  const result = await fetchPromise;

  clearInterval(intervalId);

  return result;
};
