import { FaArrowRight } from '@react-icons/all-files/fa6/FaArrowRight';
import { useRouter } from 'next/router';

import { useViewport } from 'context/viewport';
import { pageRoutes } from 'lib/routes';

import { DefaultBannerBackground } from './common/DefaultBannerBackground';
import { DefaultBannerButton } from './common/DefaultBannerButton';
import { DefaultBannerContentContainer } from './common/DefaultBannerContentContainer';
import { DefaultBannerForeground } from './common/DefaultBannerForeground';
import { DefaultBannerTitle } from './common/DefaultBannerTitle';

export const InvestorsBanner = () => {
  const { isTabletOrMobile, isMobile } = useViewport();
  const router = useRouter();
  const onClickShop = () => router.push(pageRoutes.explore());

  return (
    <DefaultBannerBackground color='#EEE9E3'>
      <DefaultBannerForeground
        image={{
          src: isTabletOrMobile ? 'investors-banner-mobile-foreground.png' : 'investors-banner-foreground.png'
        }}
        orientation={isMobile ? 'right 0px bottom 5px' : 'right 0px top 0px'}
        style={{
          transform: isMobile ? 'scale(0.85)' : undefined,
          transformOrigin: 'bottom right'
        }}
      />
      <DefaultBannerContentContainer style={{ top: '-18%' }}>
        <DefaultBannerTitle
          content='Backed by authorized retailers, watch legends, and the biggest collectors in the world'
          className='w-90 w-sm-55 text-primary'
        />
        <DefaultBannerButton textClassname='text-primary' endIcon={FaArrowRight} onClick={onClickShop}>
          Shop now
        </DefaultBannerButton>
      </DefaultBannerContentContainer>
    </DefaultBannerBackground>
  );
};
