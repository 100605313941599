import { FC } from 'react';

export const AuctionListingTagTestIds = {
  container: 'auction-listing-tag-container'
};

export type AuctionListingTagProps = {
  type: 'ending' | 'justAdded' | 'ended';
  position?: 'left' | 'right';
};

export const tagsData = {
  ending: 'ENDING SOON',
  justAdded: 'JUST ADDED',
  ended: 'ENDED'
} satisfies Record<AuctionListingTagProps['type'], string>;

export const AuctionListingTag: FC<AuctionListingTagProps> = ({ type, position = 'left' }) => (
  <div
    className='position-absolute px-2 py-1 m-3 riforma-regular fs-12px text-white rounded-1 top-0 d-flex align-items-center gap-1'
    data-testid={AuctionListingTagTestIds.container}
    style={{
      right: position === 'right' ? 0 : undefined,
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 5
    }}
  >
    {tagsData[type]}
  </div>
);
