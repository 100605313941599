import classNames from 'classnames';

import { StyledComponent } from 'types';

interface DefaultCardContent extends StyledComponent {
  title?: React.ReactNode;
  description?: React.ReactNode;
}

export const DefaultCardContent: React.FC<DefaultCardContent> = ({ title, description, className, style }) => (
  <div className={classNames('d-flex flex-column py-4 py-md-0 gap-2', className)} style={style}>
    <div className={classNames('fs-20px fs-md-24px line-height-28px line-height-md-32px')}>{title}</div>
    {description && <div className={classNames('fs-14px line-height-24px opacity-60')}>{description}</div>}
  </div>
);
