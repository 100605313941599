import classNames from 'classnames';

import { CloudinaryImage, CloudinaryImageProps } from 'components/common/CloudinaryImage';

import { DefaultBannerGraphic } from './types';

export const DefaultBannerBackgroundTestIds = {
  container: 'default-banner-background-container'
};

export interface DefaultBannerBackgroundProps extends DefaultBannerGraphic {
  image?: Omit<CloudinaryImageProps, 'responsive'>;
}

export const DefaultBannerBackground: React.FC<DefaultBannerBackgroundProps> = ({
  className,
  style,
  children,
  image,
  color,
  orientation = 'left bottom',
  size
}) => (
  <div
    data-testid={DefaultBannerBackgroundTestIds.container}
    className={classNames('d-block w-100 h-100 position-relative', className)}
    style={{
      backgroundColor: color,
      backgroundPosition: orientation,
      backgroundSize: size,
      ...style
    }}
  >
    {image && <CloudinaryImage fill sizes='100vw' style={{ objectFit: 'cover', objectPosition: 'top' }} {...image} />}
    {children}
  </div>
);
