import { FaArrowRight } from '@react-icons/all-files/fa6/FaArrowRight';

import { useViewport } from 'context/viewport';

import { DefaultCardButton } from './common/DefaultCardButton';
import { DefaultCardContainer } from './common/DefaultCardContainer';
import { DefaultCardContent } from './common/DefaultCardContent';
import { DefaultCardContentContainer } from './common/DefaultCardContentContainer';
import { DefaultCardGraphic } from './common/DefaultCardGraphic';
import { DefaultCardHeadingSubtitle } from './common/DefaultCardHeadingSubtitle';
import { DefaultCardSubtitle } from './common/DefaultCardSubtitle';

export const InvestorsCardTestIds = {
  container: 'investors-card-container'
};

export const InvestorsCard = () => {
  const { isMobile } = useViewport();

  return (
    <>
      {isMobile && <DefaultCardHeadingSubtitle content='Our Investors' />}
      <div
        data-testid={InvestorsCardTestIds.container}
        style={{
          background: 'linear-gradient(270deg, rgba(136, 123, 108, 0.21) 0%, rgba(136, 123, 108, 0.00) 64.41%), #EEE9E3'
        }}
        className='w-100 pb-2 pb-md-0'
      >
        <DefaultCardContainer
          height={650}
          style={{
            color: '#201A11'
          }}
          overrideDefaultClassname='mx-0 mx-md-3 mx-xl-auto riforma-regular'
        >
          <DefaultCardContentContainer overrideDefaultClassname='w-100 w-sm-50 ps-4 pe-4 ps-md-5 ps-lg-6 pe-md-0 py-4 pt-0 pt-md-5 pb-md-5 d-flex flex-column justify-content-center'>
            {!isMobile && <DefaultCardSubtitle content='Our investors' className='opacity-60 mb-4' />}
            <DefaultCardContent
              className='mb-2 mb-md-5'
              title='Backed by authorized retailers, watch legends, and the biggest collectors in the world'
            />
            <DefaultCardButton
              href='https://www.getbezel.com/post/bezel-seed-round'
              content='Learn more'
              endIcon={FaArrowRight}
              style={{
                backgroundColor: 'rgba(31, 25, 17, 0.06)',
                color: '#1F1911'
              }}
              target='_blank'
            />
          </DefaultCardContentContainer>
          <DefaultCardGraphic
            image={{
              src: isMobile ? 'investors-card-mobile.png' : 'investors-card-foreground-desktop.png',
              width: 3640,
              quality: 100
            }}
          />
        </DefaultCardContainer>
      </div>
    </>
  );
};
