import { FaArrowRight } from '@react-icons/all-files/fa6/FaArrowRight';

import { useViewport } from 'context/viewport';
import { intercomShowNewMessage } from 'lib/intercom';

import { DefaultBannerBackground } from './common/DefaultBannerBackground';
import { DefaultBannerButton } from './common/DefaultBannerButton';
import { DefaultBannerContentContainer } from './common/DefaultBannerContentContainer';
import { DefaultBannerForeground } from './common/DefaultBannerForeground';
import { DefaultBannerTitle } from './common/DefaultBannerTitle';

const onClickChat = () => intercomShowNewMessage();

export const ConciergeBanner = () => {
  const { isMobile, isTabletOrMobile } = useViewport();

  return (
    <DefaultBannerBackground color='#EEE9E3'>
      <DefaultBannerForeground
        image={{
          src: isTabletOrMobile ? 'concierge-banner-foreground-mobile.png' : 'concierge-banner-foreground.png'
        }}
        orientation={isMobile ? 'right -90px bottom -20px' : 'right 18% top 0px'}
        style={{
          transform: isMobile ? 'scale(0.91)' : undefined,
          transformOrigin: 'bottom right'
        }}
      />
      <DefaultBannerContentContainer style={{ top: '-15%' }}>
        <DefaultBannerTitle className='w-90 w-sm-55 text-primary'>
          Your <span className='riforma-medium'>Bezel Concierge</span> is available now to source you any watch on the
          planet. <span className='bradford-regular-italic'>Seriously, any watch.</span>
        </DefaultBannerTitle>
        <DefaultBannerButton
          content='Chat now'
          endIcon={FaArrowRight}
          textClassname='text-primary'
          onClick={onClickChat}
        />
      </DefaultBannerContentContainer>
    </DefaultBannerBackground>
  );
};
