import { ContentLoader } from 'components/design-system/ContentLoader';

export const BannerCarouselContentLoaderTestIds = {
  container: 'banner-carousel-content-loader-container'
};

export const BannerCarouselContentLoader = () => (
  <div data-testid={BannerCarouselContentLoaderTestIds.container} style={{ height: '100%' }}>
    <ContentLoader height='100%' />
  </div>
);
