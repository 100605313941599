import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { useViewport } from 'context/viewport';
import { StyledComponent } from 'types';

import styles from './DefaultCardItem.module.scss';

export const DefaultCardContainerTestIds = {
  container: 'default-card-container'
};

export interface DefaultCardContainerProps extends StyledComponent, PropsWithChildren {
  height?: number | string;
  color?: string;
  container?: StyledComponent;
  overrideDefaultClassname?: string;
}

export const DefaultCardContainer: React.FC<DefaultCardContainerProps> = ({
  className,
  style,
  height,
  color,
  children,
  container,
  overrideDefaultClassname
}) => {
  const { isMobile } = useViewport();

  return (
    <div
      data-testid={DefaultCardContainerTestIds.container}
      className={classNames(
        overrideDefaultClassname || 'riforma-regular rounded-3 overflow-hidden mx-4 mx-md-3 mx-xl-auto',
        styles['default-card-item-container'],
        className
      )}
      style={{ height: isMobile ? 'auto' : height, backgroundColor: color, ...style }}
    >
      <div
        className={classNames(
          'w-100 d-flex flex-column-reverse flex-md-row justify-content-between h-100 position-relative',
          container?.className
        )}
        style={container?.style}
      >
        {children}
      </div>
    </div>
  );
};
