import { FC } from 'react';
import { FaArrowRight } from '@react-icons/all-files/fa6/FaArrowRight';
import { useRouter } from 'next/router';

import { useViewport } from 'context/viewport';
import { pageRoutes } from 'lib/routes';

import { DefaultBannerBackground } from './common/DefaultBannerBackground';
import { DefaultBannerButton } from './common/DefaultBannerButton';
import { DefaultBannerContentContainer } from './common/DefaultBannerContentContainer';
import { DefaultBannerForeground } from './common/DefaultBannerForeground';
import { DefaultBannerTitle } from './common/DefaultBannerTitle';

export type AuctionsCarouselBannerProps = {
  auctionsCount: number;
};

const mobileOrientation = 'left 0px bottom -50px';
const tabletOrientation = 'right 0px top 0px';
const desktopOrientation = 'right 2em top 0px';

export const AuctionsCarouselBanner: FC<AuctionsCarouselBannerProps> = ({ auctionsCount }) => {
  const { isMobile, isTabletOrMobile } = useViewport();
  const router = useRouter();

  const onClickShop = () => router.push(pageRoutes.auctions);
  let orientation = '';

  if (isMobile) {
    orientation = mobileOrientation;
  } else if (isTabletOrMobile) {
    orientation = tabletOrientation;
  } else {
    orientation = desktopOrientation;
  }

  return (
    <DefaultBannerBackground
      style={{
        background: 'linear-gradient(90deg, rgba(238, 233, 227, 0.00) 46.6%, rgba(238, 233, 227, 0.10) 100%), #1F1E1D'
      }}
    >
      <DefaultBannerForeground
        image={{
          src: isMobile
            ? 'bezel-web/home_page_auctions_feature_background_mobile.png'
            : 'bezel-web/home_page_auctions_feature_background_desktop.png'
        }}
        orientation={orientation}
      />
      <DefaultBannerContentContainer style={{ top: '-18%' }}>
        <div className='fs-12px fs-sm-16px text-light opacity-60 mb-2'>BEZEL AUCTIONS</div>
        <DefaultBannerTitle
          content={`${auctionsCount} auction${auctionsCount > 1 ? 's' : ''} live now`}
          className='w-90 fs-20px fs-sm-24px w-sm-55 text-light'
        />
        <DefaultBannerButton textClassname='text-light' endIcon={FaArrowRight} onClick={onClickShop}>
          Shop now
        </DefaultBannerButton>
      </DefaultBannerContentContainer>
    </DefaultBannerBackground>
  );
};
