import { CSSProperties } from 'react';

import { useViewport } from 'context/viewport';

import { AuthAdvantageItemsButton } from './AuthAdvantageItemsButton';
import { DefaultAuthAdvantageItems } from './DefaultAuthAdvantageItems';

import { AdvantageItem } from '../common/types';

const itemDesktopStyles: CSSProperties = {
  borderLeft: '1px solid rgba(255, 252, 249, 0.2)'
};

const itemMobileStyles: CSSProperties = {
  border: '1px solid rgba(255, 252, 249, 0.10)',
  backgroundColor: 'rgba(255, 252, 249, 0.04)'
};

export const AuthAdvantageItems = () => {
  const { isMobile, isMobileSmall } = useViewport();
  const iconStyles = isMobile ? itemMobileStyles : itemDesktopStyles;

  const authAdvantageItems: AdvantageItem[] = [
    {
      title: {
        content: 'In-house authentication'
      },
      description: {
        content: 'Every watch is sent to our in-house experts for multi-point Bezel certification before it gets to you'
      },
      icon: {
        content: '01'
      },
      style: iconStyles
    },
    {
      title: {
        content: 'Diagnostic testing'
      },
      description: {
        content:
          "Every watch is put through extensive diagnostic and performance testing to ensure it's operating as expected"
      },
      icon: {
        content: '02'
      },
      style: iconStyles
    },
    {
      title: {
        content: 'Complete transparency'
      },
      description: {
        content:
          "Every watch is run through the world's largest database of stolen watches to ensure there are no open reports"
      },
      icon: {
        content: '03'
      },
      style: iconStyles
    }
  ];

  const getContainerHeight = () => {
    if (isMobileSmall) {
      return 530;
    }
    if (isMobile) {
      return 450;
    }

    return undefined;
  };

  return (
    <DefaultAuthAdvantageItems
      items={authAdvantageItems}
      container={{
        className: 'py-md-4 text-light position-relative',
        style: {
          background: 'linear-gradient(180deg, rgba(31, 25, 17, 1) 0%, #1F1911 100%)',
          boxShadow: isMobile ? '-10px 50px 40px 30px rgba(31, 25, 17, 1)' : undefined,
          transform: isMobile ? 'rotate(180deg)' : undefined, // Rotate to make shadow stay on top,
          height: getContainerHeight()
        }
      }}
      style={{
        position: isMobile ? 'absolute' : undefined,
        top: '0px',
        transform: isMobile ? 'rotate(180deg)' : undefined // Rotate back text properly
      }}
    >
      {isMobile && <AuthAdvantageItemsButton content='Learn more' />}
    </DefaultAuthAdvantageItems>
  );
};
