import classNames from 'classnames';

import { DefaultBannerText } from './types';

export const DefaultBannerSubtitleTestIds = {
  container: 'default-banner-subtitle-container'
};

export const DefaultBannerSubtitle: React.FC<DefaultBannerText> = ({ className, children, style, content }) => (
  <div
    data-testid={DefaultBannerSubtitleTestIds.container}
    className={classNames('mb-1 fs-12px', className)}
    style={style}
  >
    {content}
    {children}
  </div>
);
