import classNames from 'classnames';

import { DefaultAuthAdvantageItem } from './DefaultAuthAdvantageItem';

import { DefaultAdvantageItemsProps } from '../common/types';

export const DefaultAuthAdvantageItems: React.FC<DefaultAdvantageItemsProps> = ({
  items,
  container,
  className,
  style,
  children
}) => (
  <div className={classNames('w-100', container?.className)} style={container?.style}>
    <div
      className={classNames(
        'main-content d-flex flex-column flex-md-row riforma-regular gap-3 gap-md-5 pt-4 pb-4 pt-md-6 pb-md-5 px-4',
        className
      )}
      style={style}
    >
      {items.map((item, index) => (
        <DefaultAuthAdvantageItem key={`${item.title}-${index}`} {...item} />
      ))}
      {children}
    </div>
  </div>
);
